.about {
  /* background-color: pink; */
  height: 100%;
  margin-bottom: 10rem;
  padding-top: 5rem;
}

.about-btn {
  display: flex;
  font-family: "Averia Serif Libre", cursive;
}

.about-btn .develop_cont {
  border-top-right-radius: 20px;
}
.about-btn .design_cont {
  border-top-left-radius: 20px;
}

.active:hover {
  margin-top: -10px;
  z-index: 10;
  opacity: 1;
}
.design_cont {
  text-transform: uppercase;
  /* background-color: pink; */
  width: 50%;
  /* font-size: 70px; */
  font-size: 3.5vw;
  padding: 10px 0;
}

.develop_cont {
  text-transform: uppercase;
  /* font-size: 70px; */
  font-size: 3.5vw;
  padding: 10px 0;
  width: 50%;
  /* box-shadow: 9px -8px 16px 1px gray; */
}

.info_cont {
  display: flex;

  /* box-shadow: 0 0 20px 10px gray; */
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  /* background-color: var(--clr-fg); */
  background-color: var(--clr-fg);
}

.about_visual {
  width: 50%;
}

.info {
  width: 50%;
  /* margin-left: 50%; */
  padding: 4vw;
  /* color: var(--clr-fg-alt); */
  color: white;
  border-bottom-right-radius: 20px;
}
.info h2 {
  color: var(--clr-fg-alt);
}

.info p {
  margin-bottom: 1rem;
  font-size: 20px;
}

.gif_cont {
  display: flex;
  justify-content: center; /* horizontally center the images */
  align-items: center; /* vertically center the images */
  height: 100%;
  padding: 20px;
}

.gif_cont img {
  width: 50%;
}

@media (max-width: 600px) {
  .about {
    margin-bottom: 5rem;
  }
  .design_cont {
    font-size: 1.7rem;
  }

  .develop_cont {
    font-size: 1.7rem;
    width: 50%;
  }

  .info_cont {
    display: block;
  }
  .about_visual {
    width: 100%;
    height: 30vh;
  }

  .info {
    padding: 1rem;
    margin: 0;
    width: 100%;
  }

  .info h2 {
    font-size: 1.5rem;
  }

  .info p {
    margin-bottom: 1rem;
    font-size: 1rem;
  }
}
