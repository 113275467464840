.hero {
  display: flex;
  margin-top: 5vw;
}

.hero_txt {
  display: flex;
  flex-direction: column;
  width: 45%;
  /* background-color: blue; */
  padding: 30px 0px;
  justify-content: center;
  margin: 0 1vw;
}

.hero_txt h1 {
  font-family: "Averia Serif Libre", cursive;
}

.hero_txt h3 {
  font-size: 1.5vw;
  margin-top: 6vh;
  font-weight: 500;
  /* text-transform: uppercase; */
}
.hero_txt h4 {
  font-size: 1vw;
  text-transform: uppercase;
}
.hero_txt_title {
  color: var(--clr-primary);
}

.hero_txt_stack {
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  justify-content: space-evenly;
  margin-top: 4rem;
}

.hero_txt_stack p {
  font-size: 1vw;
  text-transform: uppercase;
  padding: 5px 15px;
  margin: 10px 5px;
  background-color: var(--clr-fg-alt);
  color: var(--clr-bg);
}

.hero_img {
  width: 55%;
  /* background-color: red; */
}

.hero_img img {
  width: 100%;
}

/* SECTION HEADER */
.vp_sections {
  width: 100%;
}

.vp_section {
  width: 100%;
  margin-bottom: 10vw;
  /* background-color: red; */
}
.vp_section_head_title {
  background-color: var(--clr-primary);
  color: var(--clr-bg);
  padding: 1rem 0;
  padding-left: 2rem;
  margin-bottom: 1rem;
}
.vp_section p {
  margin: 2.5rem 0.5rem;
  font-size: 1.5rem;
  font-weight: 500;
  padding-left: 6vw;
  color: var(--clr-fg-alt);
}

/* SECTION IMAGE */
.vp_section_img_cont {
  width: 100%;
  height: 100%;
  padding: 0 6vw;
}

.vp_section_img {
  width: 40%;
  height: auto;
  margin-bottom: 2rem;
  border: 2px outset var(--clr-primary);
  transition: all 0.1s ease-in-out;
  line-height: 0;
}

.vp_section_img:nth-of-type(even) {
  width: 40%;
  margin-left: auto;
}

.vp_section_img:hover {
  /* transform: scale(1.01); */
  cursor: pointer;
  box-shadow: 0 0px 20px var(--clr-primary);
  width: 90%;
  border: 0px outset var(--clr-primary);
}

.vp_section_img img {
  width: 100%;
  height: 100%;
}

/* .section_img.enlarged {
  position: fixed;
  width: 80%;
  height: 80%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  z-index: 9999;
} */

.btn_cont span {
  margin-bottom: 2vw;
}

@media (max-width: 901px) {
  .hero {
    display: block;
  }

  .hero_txt {
    width: 100%;
    /* background-color: red; */
  }

  .hero_txt h1 {
    font-size: 10vw;
  }

  .hero_txt_stack {
    display: flex;
    flex-wrap: wrap;
  }

  .hero_txt_stack p {
    font-size: 3vw;
  }

  .hero_img {
    width: 100%;
  }

  .vp_section p {
    margin: 2.3rem 0;
    font-size: 3vw;
    text-align: left;
  }
}

@media (max-width: 600px) {
  .hero {
    display: block;
  }

  .hero_txt {
    width: 100%;
    /* background-color: red; */
  }

  .hero_txt h1 {
    font-size: 13vw;
    text-align: center;
  }

  .hero_txt h3 {
    font-size: 5vw;
    margin-top: 4vh;
    font-weight: 500;
    /* text-transform: uppercase; */
  }
  .hero_txt h4 {
    font-size: 3.5vw;
  }

  .hero_txt_stack {
    display: flex;
    flex-wrap: wrap;
    margin-top: 2rem;
  }

  .hero_txt_stack p {
    padding: 5px 10px;
    margin: 8px 4px;
    justify-content: space-evenly;
    font-size: 4vw;
  }

  .hero_img {
    width: 100%;
  }

  /* SECTION */

  .vp_section_head_title {
    padding: 0.5rem 0;
    padding-left: 1rem;
    margin-bottom: 1rem;
    font-size: 6vw;
  }

  .vp_section p {
    margin: 2.3rem 0;
    font-size: 5vw;
    text-align: left;
  }

  /* SECTION IMAGE */
  .vp_section_img_cont {
    width: 100%;
    padding: 0 6vw;
  }

  .vp_section_img {
    width: 90%;
    margin: auto;
    margin-bottom: 2rem;
  }

  .vp_section_img:nth-of-type(even) {
    width: 90%;
    margin-left: 0;
    margin: auto;
    margin-bottom: 2rem;
  }
  .vp_section_img:hover {
    cursor: default;
    width: 100%;
  }
}
