.banner {
  z-index: 0;
  position: relative;
  background-color: rgba(0, 0, 0, 0);
}
.banner .banner-row {
  overflow: hidden;
  display: flex;
  align-items: center;
}
.banner .banner-row.center {
  justify-content: center;
  text-align: center;
}

.banner .banner-row.right {
  justify-content: flex-end;
}
.banner .banner-row .row-title,
.banner .banner-row .row-letter {
  /* font-size: 5.5rem; */
  font-size: 7vw;
  font-weight: 700;
  position: relative;
  letter-spacing: -0.1rem;
  display: inline-block;
  white-space: nowrap;
}
.banner .banner-row .row-col {
  width: 50%;
  display: flex;
  padding-left: 2rem;
}

.ban_and .banner-row {
  position: absolute;
  z-index: 10;
  top: 50%;
  left: 88%;
  transform: translate(-50%, -50%);
  background-color: transparent;
}

.ban_and .banner-row .row-title,
.ban_and .banner-row .row-letter {
  /* font-size: 4rem; */
  font-size: 6vw;
  font-family: "Averia Serif Libre", cursive;
  background-color: transparent;
}

.ban_title .banner-row .row-title,
.ban_title .banner-row .row-letter {
  font-size: 2rem;
  letter-spacing: 0.6rem;
  font-family: "Averia Serif Libre", cursive;
}

@media (max-width: 600px) {
  .banner .banner-row .row-col {
    padding-left: 0;
  }
  .banner .banner-row .row-title,
  .banner .banner-row .row-letter {
    /* font-size: 3.5rem; */
    font-size: 14vw;
  }

  .banner .banner-row .row-col {
    margin-left: 1rem;
  }

  .ban_and .banner-row {
    top: 50%;
    left: 85%;
  }

  .ban_and .banner-row .row-title,
  .ban_and .banner-row .row-letter {
    font-size: 10vw;
  }

  .ban_title .banner-row .row-title,
  .ban_title .banner-row .row-letter {
    /* font-size: 1.5rem; */
    font-size: 7vw;
  }
}
