#projects {
  color: red;
}

.archive_desc {
  text-align: center;
}

.txt_stack {
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  justify-content: space-around;
  margin: 1rem 1.5rem;
}

.txt_stack p {
  font-size: 1vw;
  text-transform: uppercase;
  padding: 5px 13px;
  /* margin: 10px 5px; */
  /* background-color: var(--clr-primary); */
  background-color: var(--clr-primary);
  color: var(--clr-bg);
}

.archive_btn {
  width: 20%;
  margin: auto;
  text-align: center;
}

.archive_cont {
  margin-bottom: 1vh;
}

/* FILTER BUTTON */
.filter-container {
  text-align: center;
}
.filter-container button {
  margin-right: 2rem;
  margin-bottom: 2rem;
  min-width: 5rem;
  height: 2rem;
  padding: 0, 5rem 1rem;
  /* background-color: white; */
  background-color: var(--clr-bg);
  /* color: rgb(65, 98, 168); */
  color: var(--clr-primary);
  border-radius: 1rem;
  border: 2px solid var(--clr-primary);
  font-weight: bold;
  cursor: pointer;
}
button.active {
  /* background: rgb(65, 98, 168); */
  background-color: var(--clr-primary);

  color: white;
}

@media (max-width: 900px) {
  .txt_stack {
    justify-content: space-around;
    margin: 1rem 1.5rem;
  }

  .txt_stack p {
    font-size: 2vw;
    text-transform: uppercase;
    padding: 5px 13px;
    /* margin: 10px 5px; */
    background-color: var(--clr-primary);
    color: var(--clr-bg);
  }
}

@media (max-width: 600px) {
  .txt_stack {
    display: flex;
    flex-wrap: wrap;
  }

  .txt_stack p {
    margin-top: 1rem;
    font-size: 2vw;
  }
  .archive_btn {
    width: 100%;
  }
}
