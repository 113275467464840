.new_cont {
  color: var(--clr-primary);
}

.desc_right {
  width: 50%;
  height: 100%;
  display: flex;
  align-items: flex-end;
  height: 80vh;
}
.desc_right p {
  margin-left: 1rem;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 33px;
  margin-bottom: 2rem;
}
.desc_right a {
  color: var(--clr-primary);
  text-decoration: underline;
}
.desc_right a:hover {
  color: var(--clr-fg);
}

.new_cont {
  /* background-color: orange; */
  display: flex;
  padding-top: 5rem;
}

.banner_cont_left {
  width: 50%;
  /* background-color: green; */
}

@media (max-width: 600px) {
  .new_cont {
    /* background-color: orange; */
    display: block;
    padding-top: 3rem;
  }

  .banner_cont_left {
    width: 100%;
  }

  .desc_right {
    width: 100%;
    height: 65vh;
    /* background-color: green; */
  }

  .desc_right p {
    margin-left: 1rem;
    font-size: 1.8rem;
  }
}
