.project {
  padding: 1em 4rem;
  margin: 0 auto;
  position: relative;
  color: var(--clr-primary);
}

.project_title {
  /* position: absolute; */
  /* top: 20%;
  left: 50%; */
  /* transform: translate(-50%, -50%); */
  width: 100%;
  background-color: rgba(255, 255, 255, 0);
  color: var(--clr-fg-alt);
  display: flex;
  align-items: first baseline;
}

.project_name {
  /* font-family: "Averia Serif Libre", cursive; */
  text-transform: uppercase;
  color: var(--clr-primary);
  font-size: 1.6vw;
  width: 100%;
}

.project_description {
  font-size: 1vw;
  text-transform: uppercase;
  font-weight: 700;
  align-items: end;
  text-align: right;
  width: 60%;
}

.img_cont {
  margin: auto;
  margin-top: 1vh;
  width: 80%;
  height: 310px;
  transition: all 0.3s ease-in-out;

  /* transition-timing-function: ease-in-out; */
  /* transition-delay: 0s; */
  cursor: pointer;
  border: 2px outset var(--clr-primary);
}

.img_cont:hover {
  box-shadow: 0px 0px 20px var(--clr-primary);
}

.img_cont img {
  width: 100%;
  height: 100%;
}

@media (max-width: 901px) {
  .project_name {
    font-size: 3vw;
  }
  .project_description {
    font-size: 1.1vw;
  }
}

@media (max-width: 600px) {
  .project {
    padding: 1em 0rem;
    margin: 0 auto;
  }

  .project_title {
    display: flex;
    align-items: first baseline;
  }

  .project_name {
    font-size: 6vw;
    width: 70%;
  }

  .project_description {
    font-size: 0.7rem;
  }

  .img_cont {
    width: 319px;
    height: 219px;
  }
}
