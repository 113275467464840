.repos {
  /* display: flex; */
  flex-wrap: wrap;
  justify-content: center;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  width: 100%;
}

.section_header {
  position: relative;
}

.filter-repos {
  margin-bottom: 2rem;
  position: relative;
  float: right;
  margin-top: 4vh;
}

.filter-repos input {
  width: 350px;
  height: 48px;
  padding: 0 58px 0 28px;
  border-radius: 30px;
  border: 2px solid var(--clr-primary);
  outline: 0;
  box-shadow: 2px 4px 8px 0 rgba(0, 0, 0, 0.15);
  transition: all 300ms ease;
  margin-top: 4vh;
}
.repo__list {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
}

.repo__list li {
  /* background-color: red; */
  width: 30%;
  min-height: 180px;
  border-radius: 10px;
  margin: 1em;
  border: 2px solid var(--clr-fg-alt);
  padding: 1em;
  text-align: center;
  /* box-shadow: var(--shadow); */
  transition: transform 0.2s linear;
}

.repo__list li:hover {
  box-shadow: 0 3px 6px var(--clr-primary);

  transform: translateY(-7px);
}

.repo__list li a {
  padding: 10px 20px;
  border-radius: 30px;
}

.repo__list li a:hover {
  color: var(--text);
}

.repo h3 {
  font-family: "Averia Serif Libre", cursive;
  font-size: 2vw;
}

.lang-text {
  color: var(--clr-fg-alt);
  /* font-size: 1rem; */
  font-size: 1.2vw;
}

.repos .link {
  padding: 0 0 0.3em 0;
  color: var(--clr-fg);
}

.repos .link:hover {
  color: var(--clr-primary);
}

/* .repos .link--icon {
} */

.link::before {
  /* content: ""; */
  display: inline;
  width: 0%;
  height: 0.2em;
  position: absolute;
  bottom: 0;
  /* background-color: var(--clr-primary); */
  /* background-color: red; */
  transition: width 0.2s ease-in;
}

.abt-btn {
  width: 100px;
  padding: 0.5em 1em;
  margin: auto;
  text-align: center;
}

/* selected git */
.selected_repo_cont {
  margin-bottom: 5vh;
}

.selected_repo {
  height: 100%;
}

.sub_title {
  text-align: center;
  position: relative;
  /* font-size: 1.5vw; */
  font-family: "Averia Serif Libre", cursive;
  font-size: 30px;
  margin: 0;
  margin: auto;
  color: var(--clr-primary);
}
.repo_title {
  color: var(--clr-primary);
}
.sub_titlea {
  padding-left: 25%;
}

.repo_description {
  margin: 0;
  padding: 0;
  padding-left: 0;
  color: var(--clr-fg-alt);
}

.repo_stack {
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  justify-content: space-evenly;
  margin-top: 1rem;
}

.repo_stack p {
  /* height: 4vh; */
  font-size: 1vw;
  text-transform: uppercase;
  padding: 3px 7px;
  margin: 6px 2px;
  background-color: var(--clr-fg-alt);
  color: var(--clr-bg);
}

@media (max-width: 901px) {
  .repo h3 {
    font-size: 2.4vw;
  }
  .lang-text {
    font-size: 1.4vw;
  }
  .filter-repos input {
    justify-content: center;
  }
}

@media (max-width: 600px) {
  .filter-repos {
    float: none;
  }

  .repo h3 {
    font-size: 4.5vw;
  }

  .lang-text {
    font-size: 2vw;
  }

  .filter-repos input {
    width: 100%;
    align-items: center;
  }

  .repo__list li {
    width: 80%;
  }

  .selected_repo_cont {
    width: 100%;
  }
  .selected_repo {
    height: 100%;
    width: 100%;
  }

  .repo_stack {
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    justify-content: space-evenly;
    margin-top: 1rem;
  }

  .repo_stack p {
    font-size: 4vw;
    text-transform: uppercase;
    padding: 3px 7px;
    margin: 6px 2px;
    background-color: var(--clr-primary);
    color: var(--clr-bg);
  }

  .sub_title {
    text-align: center;
    position: relative;
    /* font-size: 1.5vw; */
    font-family: "Averia Serif Libre", cursive;
    font-size: 6vw;
    margin: 0;
    margin: auto;
  }

  .sub_titlea {
    padding-left: 0;
  }
}
