.app {
  font-family: "Poppins", sans-serif;
  line-height: 1.5;
  color: var(--clr-fg);
  background-color: var(--clr-bg);
}

.apsp {
  /* background: linear-gradient(132deg, #fc415a, #591bc5, #212335); */
  background: linear-gradient(132deg, var(--clr-bg), var(--clr-fg));
  background-size: 400% 400%;
  animation: Gradient 15s ease infinite;
  /* position: relative;
  height: 100vh;
  width: 100%;
  overflow: hidden;
  padding: 0;
  margin: 0px; */
}

/* Animate Background*/
@keyframes Gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.hide {
  display: none !important;
}

/* .light {
  --clr-bg: #fcfcfc;
  --clr-bg-alt: #fff;
  --clr-fg: #555;
  --clr-fg-alt: #444;
  --clr-primary: #475161;
  --shadow: rgba(32, 32, 212, 0.9) 0px 7px 29px 0px;
}
*/
.dark {
  --clr-bg: #121212;
  --clr-bg-alt: #282828;
  --clr-fg: #37362e;
  --clr-fg-alt: #94948f;
  --clr-primary: #c6bc4e;
  --shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
}
.light {
  --clr-bg: #fcfcfc;
  --clr-bg-alt: #fff;
  --clr-fg: #555;
  --clr-fg-alt: #444;
  --clr-primary: #475161;
  --shadow: rgba(32, 32, 212, 0.9) 0px 7px 29px 0px;
}

/* .dark {
  --clr-bg: #202529;
  --clr-bg-alt: #32383d;
  --clr-fg: #3d80b3;
  --clr-fg-alt: #ffffff;
  --clr-primary: #a1b4c4;
  --shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
} */

main {
  /* max-width: 1100px;s */
  width: 95%;
  margin: 0 auto;
}

.section {
  margin-top: 5em;
}

/* .section__title {
  text-align: center;
  margin-bottom: 1em;
  text-transform: uppercase;
} */

.section__title {
  font-family: "Averia Serif Libre", cursive;
  font-weight: 700;
  font-size: 30px;
  margin-left: 2rem;
  margin-bottom: 1rem;
}

.center {
  display: flex;
  align-items: center;
}

.link {
  color: var(--clr-primary);
  padding: 0 0 0.3em 0;
  position: relative;
}

.link:hover {
  color: var(--clr-primary);
}

.link::before {
  content: "";
  display: inline;
  width: 0%;
  height: 0.2em;
  position: absolute;
  bottom: 0;
  background-color: var(--clr-primary);
  transition: width 0.2s ease-in;
}

.link:hover::before,
.link:focus::before {
  width: 100%;
}

.link--nav {
  /* color: var(--clr-fg); */
  color: var(--clr-fg-alt);

  text-transform: lowercase;
  font-weight: 500;
}

.link--icon {
  color: var(--clr-primary) f;
}
.link--icon:hover {
  color: var(--clr-primary);
}

.btn {
  display: block;
  cursor: pointer;
  padding: 0.8em 1.4em;
  font-weight: 500;
  font-size: 0.9rem;
  text-transform: lowercase;
  transition: transform 0.2s ease-in-out;
}

.btn--outline {
  color: var(--clr-primary);
  border: 2px solid var(--clr-primary);
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.btn--outline:hover,
.btn--outline:focus {
  color: var(--clr-bg);
}

.btn--outline:before {
  content: "";
  position: absolute;
  background-color: var(--clr-primary);
  right: 100%;
  bottom: 0;
  left: 0;
  top: 0;
  z-index: -1;
  transition: right 0.2s ease-in-out;
}

.btn--outline:hover:before,
.btn--outline:focus:before {
  right: 0;
}

.btn--plain {
  text-transform: initial;
  background-color: var(--clr-bg-alt);
  box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 3px 0px;
  border: 0;
}

.btn--plain:hover {
  transform: translateY(-4px);
}

.btn--icon {
  padding: 0;
}

.btn--icon:hover,
.btn--icon:focus {
  color: var(--clr-primary);
}

.btn--icon:active {
  transform: translateY(-5px);
}

@media (max-width: 600px) {
  .section {
    margin-top: 4em;
  }
}

.head--font {
  font-family: "Averia Serif Libre", cursive;
}

.body--font {
  font-family: "Averia Serif Libre", cursive;
}
